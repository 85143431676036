/* eslint-disable no-restricted-imports */
/**
 * This file wraps the "react-router" API, such that the match.param values
 * are URI-decoded before they are passed on.
 */
import { withSentryReactRouterV6Routing } from "@sentry/react";
import { useContext, useEffect, useMemo } from "react";
import {
  type NavigateFunction,
  type NavigateOptions,
  Routes as OriginalRoutes,
  type Path,
  // eslint-disable-next-line camelcase
  UNSAFE_RouteContext,
  resolvePath,
  useLocation,
  useNavigate as useNavigateImpl,
} from "react-router";

import { URLTemplate } from "~/utils/URLTemplate";
import { useEffectEvent } from "~/utils/useEffectEvent";

export * from "react-router";
export {
  BrowserRouter,
  Link,
  NavLink,
  type NavLinkProps,
} from "react-router-dom";

/**
 * The `useMatches` function of react router only works with the data router,
 * which we don't use (yet). This is a workaround.
 *
 * https://reactrouter.com/en/6.11.2/hooks/use-matches
 */
function useUnsafeMatches() {
  const { matches } = useContext(UNSAFE_RouteContext);
  return matches;
}

export function useCurrentRouteTemplate() {
  const match = useUnsafeMatches().at(-1);

  return useMemo(() => {
    const element = match?.route?.element;
    if (element == null) return undefined;
    if (typeof element !== "object" || !("props" in element)) return undefined;
    const template = element.props?.template;
    if (template instanceof URLTemplate) return template;
    return undefined;
  }, [match]);
}

export const Routes = withSentryReactRouterV6Routing(OriginalRoutes);

function urlFromRelative(path: RelativeURL) {
  const url = new URL(path.pathname, window.location.href);
  url.search = path.search;
  url.hash = path.hash;
  return url;
}

type RelativeURL = Path & { origin?: never };

function isSameOrigin(url: URL) {
  return url.origin === new URL(window.location.href).origin;
}

function normalize(
  to: URL | string | Partial<Path>,
): { external: URL } | { internal: Partial<RelativeURL> } {
  if (typeof to !== "string" && !("origin" in to)) {
    return { internal: to };
  }

  const url = new URL(to, window.location.href);
  if (!isSameOrigin(url)) return { external: url };

  return {
    internal: { pathname: url.pathname, search: url.search, hash: url.hash },
  };
}

function isInSubPath(path: string, subPath: string) {
  return path === subPath || path.startsWith(`${subPath}/`);
}

export function isSPARoute({ pathname }: Partial<RelativeURL>): boolean {
  if (pathname == null) return true;
  if (isInSubPath(pathname, "/mflt-sudo")) return false;
  if (isInSubPath(pathname, "/api")) return false;
  if (isInSubPath(pathname, "/auth")) return false;
  return true;
}

function browserNavigate(to: URL, options: NavigateOptions | undefined) {
  if (options?.replace) {
    window.location.replace(to);
  } else {
    window.location.assign(to);
  }
}

export function useNavigate() {
  const impl = useNavigateImpl();

  return useEffectEvent<NavigateFunction>((to, options?: NavigateOptions) => {
    if (typeof to === "number") {
      impl(to);
      return;
    }

    const normalized = normalize(to);

    if ("external" in normalized) {
      browserNavigate(normalized.external, options);
      return;
    }

    if (!isSPARoute(normalized.internal)) {
      browserNavigate(
        urlFromRelative(resolvePath(normalized.internal)),
        options,
      );
      return;
    }

    impl(normalized.internal, options);
  });
}

/**
 * This is a safe-guard to detect situations where the SPA performed
 * a page navigation that should have been handled by the browser
 * as it should have requested the page from the server.
 */
export function NonSPARouteHandler({
  children,
  fallback,
}: React.PropsWithChildren<{ fallback: React.ReactNode }>) {
  const location = useLocation();

  const detected = useMemo(() => {
    return !isSPARoute(location);
  }, [location]);

  useEffect(() => {
    if (!detected) return;
    window.location.replace(window.location.href);
  }, [detected]);

  return <>{detected ? fallback : children}</>;
}
