/**
 * Special error type that can be used to signal that an operation was aborted.
 *
 * Similar variants are `require('axios').Cancel` or `require('react-query').CancelledError`.
 */
export class AbortedError extends Error {}

export function isAbortedError(value: unknown): value is AbortedError {
  return value instanceof AbortedError;
}
