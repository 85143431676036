import { useAtomValue } from "jotai";
import { type PropsWithChildren, useEffect } from "react";
import type { ConditionalKeys } from "type-fest";

import { devToolsEnabledAtom } from "~/contexts/devToolsEnabledAtom";

type Spotlight = typeof import("@spotlightjs/spotlight");

const deferred = Promise.withResolvers<Spotlight>();

/**
 * Forward a function call to the Spotlight module (once it's loaded).
 * @param functionName
 */
function forward<K extends ConditionalKeys<Spotlight, Function>>(
  functionName: K,
) {
  return async (
    ...args: Parameters<Spotlight[K]>
  ): Promise<ReturnType<Spotlight[K]>> => {
    const spotlight = await deferred.promise;
    const f = spotlight[functionName] as (
      ...args: Parameters<Spotlight[K]>
    ) => ReturnType<Spotlight[K]>;
    return f(...args);
  };
}

/**
 * @public
 */
export const trigger = forward("trigger");

/**
 * @public
 */
export const openSpotlight = forward("openSpotlight");

/**
 * Initializes Spotlight.
 */
export async function init() {
  if (!import.meta.env.DEV || !APP_CONFIG.SENTRY_SPOTLIGHT_ENABLED) return;
  const module = await import("@spotlightjs/spotlight");
  const { init: initImpl, sentry } = module;
  await initImpl({
    injectImmediately: true,
    anchor: "centerRight",
    integrations: [sentry()],
    experiments: {
      "sentry:focus-local-events": false,
    },
  });
  deferred.resolve(module);
}

export function SpotlightController({ children }: PropsWithChildren) {
  const devToolsEnabled = useAtomValue(devToolsEnabledAtom);

  useEffect(() => {
    const root = document.getElementById("sentry-spotlight-root");
    root?.setAttribute(
      "style",
      devToolsEnabled ? "display: block" : "display: none",
    );
  }, [devToolsEnabled]);

  return <>{children}</>;
}
