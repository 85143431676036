import { isCancelledError } from "@tanstack/react-query";
import { isCancel } from "axios";
import type { SetReturnType } from "type-fest";

import { isAbortedError } from "~/utils/AbortedError";
import { captureException } from "~/utils/sentry";

// Node.js' types accept "any" as first parameter, let's make it stricter to
// catch mistakes:
type TypeSafeAssert = (
  condition?: boolean,
  message?: string,
  ...data: any[]
) => void;

export type Logger = {
  log: (message?: string, ...args: any[]) => void;
  debug: (message?: string, ...args: any[]) => void;
  info: (message?: string, ...args: any[]) => void;
  warn: (message?: string, ...args: any[]) => void;
  error: (message?: string, ...args: any[]) => void;
  assert: TypeSafeAssert;
  reportError: SetReturnType<typeof captureException, string | undefined>;
};

export const logger: Logger = {
  log: console.log.bind(console),
  debug: console.debug.bind(console),
  info: console.info.bind(console),
  warn: console.warn.bind(console),
  error: console.error.bind(console),
  assert: console.assert.bind(console),
  reportError: (error, captureContext) => {
    if (isAbortedError(error)) return undefined;
    if (isCancelledError(error)) return undefined;
    if (isCancel(error)) return undefined;
    console.error(error);

    return captureException(error, captureContext);
  },
};
