import { useEffect } from "react";

/**
 * Requires a #boot-ripple element to already exist in the DOM. This is done so
 * that the animation played by our index.html #boot-ripple is not interrupted
 * when the React bundle loads, or when page transitions happen.
 */
export function BootRipple() {
  useEffect(() => {
    const rippleElement = document.getElementById("boot-ripple");
    if (rippleElement?.classList.contains("boot-ripple-hidden")) {
      rippleElement.classList.remove("boot-ripple-hidden");
    }

    return () => {
      if (rippleElement) {
        rippleElement.classList.add("boot-ripple-hidden");
      }
    };
  }, []);

  return null;
}
